import { Component, OnInit, Injectable, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DeactivationGuarded } from 'src/app/shared/gaurds/candeactivate.guard';
import { ReconciliationService } from '../services/reconciliation.service'
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { CommonService } from '../services/common.service';
import { FetchTransactionsRequest, FetchTransactionsResponse, RecTransactionsList, QboRecalculationRequest } from '../models/reconciliationModel';
//import { AvaTaxCompany } from '../models/avataxcompany';
//import { salesTaxItem } from '../models/QBOUsers';
import { userCredentials } from '../models/UserCredentials';
import { OAuthSessionResponse } from '../../shared/models/OAuthToken';
//import { AvaTaxAccountValidationResponse } from '../models/AvaTaxAccountValidationResponse';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from "angular-datatables";
import { HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Constants } from '../../shared/models/Constants';
import { CustomAdapter, CustomDateParserFormatter } from '../../shared/injectables/ngDatePicker.injectable';

@Component({
  selector: 'app-reconciliation',
  templateUrl: './reconciliation.component.html',
  styleUrls: ['./reconciliation.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class ReconciliationComponent implements OnInit, DeactivationGuarded {

  searchForm: FormGroup;
  oAuthToken: OAuthSessionResponse;
  userCreds: userCredentials;
  errorMessage: any;
  showAlert: boolean = false
  alertMessage: string
  alertCssClass: string;
  scrollTop: number = 0
  showValidateAvaTaxAccountButton: boolean;
  accountValidationMessage: string;

  submitted: boolean = false;
  setMinDate: any;
  setMaxDate: any;

  consistentCount: number = 0;
  inConsistentCount: number = 0;
  totalCount: number = 0;
  errorTransactionCount: number = 0;
  inconsistentList: Array<RecTransactionsList> = [];
  isInconsistentList: boolean = false;
  selectedIds: Array<string> = [];
  isSelectAllChecked: boolean = false;
  qboRecalculationRequest: Array<QboRecalculationRequest> = [];

  avaTaxStartDate: string = "";

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  isPageRefreshed: boolean = false;
  mySubscription: any;
  sourceParam: string;
  showCopiedMessage: boolean = false;

  constructor(private fb: FormBuilder,
    private reconciliationService: ReconciliationService,
    private commonService: CommonService,
    private spinnerService: SpinnerService,
    private authService: AuthenticationService,
    private router: Router,
    private datePipe: DatePipe,
    private route: ActivatedRoute) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });
  }

  canDeactivate(): boolean | Promise<boolean> {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      if (this.searchForm && this.searchForm.dirty) {
        return window.confirm('There are some unsaved changes. Do you really want to navigate away?')
      }
    }
    return true;
  }

  initiliazeFormModel() {
    this.searchForm = this.fb.group({
      FromDate: [{ value: '', disabled: false }, Validators.required],
      ToDate: [{ value: '', disabled: false }, Validators.required],
      ErrorTransactionOnly: [{ value: false, disabled: false }]
    }, {
      validator: [
        this.dateLessThan('FromDate', 'ToDate'),
        this.dateLTET3Months('FromDate', 'ToDate')
      ]
    });

    let currDate = new Date(localStorage.getItem("_uetsid_exp"));
    let maxDate = new Date();
    this.setMaxDate = { year: maxDate.getFullYear(), month: maxDate.getMonth() + 1, day: maxDate.getDate() };

    maxDate.setDate(maxDate.getDate() - 365);
    this.setMinDate = { year: maxDate.getFullYear(), month: maxDate.getMonth() + 1, day: maxDate.getDate() };

  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      let fval = this.datePipe.transform(f.value, Constants.DateDBFormat);
      let tval = this.datePipe.transform(t.value, Constants.DateDBFormat);

      let frmDt = new Date(fval + "T00:00:00");
      let toDt = new Date(tval + "T00:00:00")

      if (frmDt > toDt) {
        t.markAsTouched();
        return {
          dateLessThan: true
        };
      }
      return null;
    }
  }

  dateLTET3Months(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      let fval = this.datePipe.transform(f.value, Constants.DateDBFormat);
      let tval = this.datePipe.transform(t.value, Constants.DateDBFormat);

      let frmDt = new Date(fval + "T00:00:00");
      let toDt = new Date(tval + "T00:00:00")

      let diffInDays = Math.floor(Math.abs(<any>frmDt - <any>toDt) / (1000 * 60 * 60 * 24));

      if (diffInDays >= 90) {
        t.markAsTouched();
        return {
          dateLTET3Months: true
        };
      }
      return null;
    }
  }

  async ngOnInit() {

    this.sourceParam = this.route.snapshot.paramMap.get("source");
    this.sourceParam = this.sourceParam == null ? "" : this.sourceParam;
  
    if (this.sourceParam != "") {
      $("#connectorHeader").hide();
      $("#dvLoginDetail").hide();
      $("#avaHeader").show();
      $("#avaHeader-content").show();
    }
  
    this.initiliazeFormModel();
    
    this.initializeDatatables();

    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {
      this.spinnerService.show();

      // Refresh JWT Token - Extend 25mins more with new JWT Token from API
      await this.commonService.extendJwtDuration();

      this.getStartDateFromConfig(this.oAuthToken.realmId);

      // On page refresh - set localstorage values in dates, and reload data
      if (!this.router.navigated) {
        var searchText = JSON.parse(localStorage.getItem("search-text"));
        if (searchText) {
          this.searchForm.patchValue({
            FromDate: searchText[0],
            ToDate: searchText[1]
          });
          this.searchForm.updateValueAndValidity({});
          this.searchTransactions();
        }
        else {
          var today = new Date();
          var toDt = this.datePipe.transform(new Date(), Constants.DateInputFormat);
          var frmDt = this.datePipe.transform(today.setDate(today.getDate() - 89), Constants.DateInputFormat);
          //console.log('today.getDate() + 90:toDt', toDt, today.setDate(today.getDate() - 91));
          this.searchForm.patchValue({
            FromDate: frmDt,
            ToDate: toDt
          });
          this.searchForm.updateValueAndValidity({});
        }
      }
      this.spinnerService.hide();
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }
  }
  

  initializeDatatables() {
    this.dtOptions[0] = {
      retrieve: true,
      paging: true,
      lengthChange: true,
      searching: true,
      pageLength: 10,
      columnDefs: [{ targets: 3, orderable: false }],
      pagingType: 'simple_numbers',
      order: [[1, 'desc']],
      serverSide: false,
      processing: false
    }
  }

  objectToHttpParams(obj: any) {
    return Object.entries(obj || {}).reduce((params, [key, value]) => {
      return params.set(
        key,
        value === 'object' ? JSON.stringify(value) : String(value)
      );
    }, new HttpParams());
  }

  getStartDateFromConfig(realmId){
    this.authService.getUpdatedConfigInfo(realmId).then((config) => {
      this.avaTaxStartDate = config.avaTaxStartDate;
      if(this.avaTaxStartDate){
        this.avaTaxStartDate = this.datePipe.transform(this.avaTaxStartDate, Constants.DateInputFormat)
      }
    }).catch((error) => console.log(error));
  }

  searchTransactions() {
    this.submitted = true;
    this.consistentCount = 0;
    this.inConsistentCount = 0;
    this.totalCount = 0;
    this.errorTransactionCount = 0;
    this.inconsistentList = [];
    this.rerender();

    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {

      if (!this.searchForm.invalid) {
        var fetchTransactionsRequest = new FetchTransactionsRequest();
        fetchTransactionsRequest.FromDate = this.datePipe.transform(this.searchForm.get('FromDate').value, Constants.DateDBFormat);
        fetchTransactionsRequest.ToDate = this.datePipe.transform(this.searchForm.get('ToDate').value, Constants.DateDBFormat);
        fetchTransactionsRequest.ErrorTransactionOnly = this.searchForm.get('ErrorTransactionOnly').value as boolean;

        this.compareTransctions(this.oAuthToken.userId, fetchTransactionsRequest, this.oAuthToken.session_Id);

        localStorage.setItem("search-text", JSON.stringify([this.searchForm.get('FromDate').value, this.searchForm.get('ToDate').value]));
        this.submitted = false;
      }
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }
  }

  compareTransctions(id: string, fetchTransactionsRequest: FetchTransactionsRequest, authToken: string): void {
    try {
      var current = this;

      this.spinnerService.show();
      this.reconciliationService.compareTransactions(id, fetchTransactionsRequest, authToken).subscribe(
        (response: any) => {
          if (response.isStartDateError == true) {
            console.log("Given date range is less than start date "+ this.datePipe.transform(response.startDate, Constants.DateInputFormat) +", so transaction can not be searched.");
            this.spinnerService.hide();
          }
          else if (response.isSentToSearch == true) {
            console.log("Transactions sent to lambda for comparison")
            this.spinnerService.show();
            this.IterativeFetch();
            //this.spinnerService.hide();
          }
          else {
            console.log("Transaction search sqs is not created.")
          }
        },
        (error: any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if(this.errorMessage != "unauthorized")
            alert(this.errorMessage);
        },
        () => { }
      );
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }
  }

  IterativeFetch() {
    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {

      this.fetchComparedTransctions(this.oAuthToken.realmId, this.oAuthToken.session_Id, 0);
    }
  }

  fetchComparedTransctions(realmId: string, authToken: string, iterationCount: number): void {
    try {
      var current = this;

      this.spinnerService.show();
      this.reconciliationService.fetchComparedTransactions(realmId, authToken).subscribe(
        (model: any) => {

          console.log("Model: ", model);

          if (model.userStatus != null && model.userStatus != undefined) {
            if (model.userStatus != "Active") {
              alert("Integration status (Calculation active) must be enabled for this feature, please go back to home screen and enable it!");
            }
            else (model.inconsistentData)
            {
              for (var data of model.inconsistentData) {
                let recList = new RecTransactionsList();
                recList.qboTxnType = data.qboTxnType;
                recList.qboTxnId = data.qboTxnId;
                recList.txnDate = data.txnDate;
                recList.qboInvNo = data.qboInvNo;
                recList.qboTotAmt = data.qboTotAmt;
                recList.qboTotTax = data.qboTotTax;
                recList.syncToken = data.syncToken;
                recList.avataxTxnId = data.avaTxnId;
                recList.avataxTxnType = data.avaTxnType;
                recList.avataxTotAmt = data.avaTotAmt;
                recList.avataxTotTax = data.avaTotTax;
                recList.errorMessage = this.formatErrorMessage(data) || data.errorMessage;
                recList.errorFrom = data.errorFrom;
                current.inconsistentList.push(recList);
              }

              current.totalCount = model.totalCount;
              current.consistentCount = model.consistentCount;
              current.inConsistentCount = model.totalCount - model.consistentCount;
              current.errorTransactionCount = model.errorTransactionCount;

              if (current.inconsistentList.length > 0)
                current.isInconsistentList = true;

              current.rerender();
              //console.log('inconsistentList:', current.inconsistentList, current.totalCount);
            }
            this.spinnerService.hide();
          }
          else {
            if (iterationCount <= 180) { //15mins :- 180 calls every 5secs.
              setTimeout(function () {
                console.log("iterationCount:" + iterationCount);
                current.fetchComparedTransctions(realmId, authToken, iterationCount++);
              }, 5000);
            }
          }
        },
        (error: any) => {
          this.errorMessage = <any>error;
          this.spinnerService.hide();
          if(this.errorMessage != "unauthorized")
            alert(this.errorMessage);
        },
        () => { }
      );
    }
    catch (error) {
      console.error(error.message)
      this.spinnerService.hide()
    }
  }

  formatErrorMessage(data): string {
    if (data === null || data === undefined) return "";
    
    let errorMessage = data.errorMessage || "";
    if (data.errorCode) {
      errorMessage += "\nErrorCode: " + data.errorCode;
    }

    if (data.errorCode === "3202" || data.errorCode === "AuthenticationException") {
      errorMessage += "\n---\n";
      errorMessage += Constants.REINTEGRATE_ERROR_MESSAGE;
    }

    return errorMessage;
  }

  rerender(): void {
    if (this.dtElements) {
      this.dtElements.forEach((dtElement: DataTableDirective) => {
        dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.clear();
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      });
    }
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();

    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
    localStorage.removeItem("search-text");
  }

  selectAll(event) {
    if (event.target.checked) {
      this.isSelectAllChecked = true;
      for (var row of this.inconsistentList) {
        row.isSelected = true;
      }
      alert("All (" + this.inconsistentList.length + ") transactions will be selected.")
    }
    else {
      var allSelected = true;
      for (var row of this.inconsistentList) {
        if (!row.isSelected) allSelected = false;
      }
      if (allSelected) {
        for (var row of this.inconsistentList) {
          row.isSelected = false;
        }
      }
    }
  }

  selectTransactionValue(target) {
    let isSelected = target.checked;
    for (var row of this.inconsistentList) {
      if (row.qboTxnId == target.value) {
        row.isSelected = isSelected;
        break;
      }
    }
    if (!target.checked) {
      this.isSelectAllChecked = false;
    }
  }

  copyText(value: string): void {
    navigator.clipboard.writeText(value).then(() => {
      // Show the copied message
      this.showCopiedMessage = true;
      
      setTimeout(() => {
        this.showCopiedMessage = false;
      }, 750);
    })
    .catch(() => {
      console.error("Unable to copy text");
    });
  }

  clearForm() {
    this.searchForm.patchValue({
      FromDate: "",
      ToDate: ""
    });

    this.searchForm.reset({});
    this.submitted = false;

    this.initializeDatatables();

    this.consistentCount = 0;
    this.totalCount = 0;
    this.errorTransactionCount = 0;
    this.inconsistentList = [];
    this.qboRecalculationRequest = [];
    this.isInconsistentList = false;

    this.dtTrigger.next();
    localStorage.removeItem("search-text");
  }

  sendToRecalculation() {

    this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
    if (this.oAuthToken) {

      let isSelected = false;
      for (var row of this.inconsistentList) {
        if (row.isSelected) {
          isSelected = true;
          break;
        }
      }

      if (isSelected) {
        for (var row of this.inconsistentList) {
          if (row.isSelected) {
            if (row.qboTxnId != "") {
              var req: QboRecalculationRequest = {
                Id: row.qboTxnId,
                EntityType: row.qboTxnType.replace(/\s+/g, ""),
                SyncToken: row.syncToken
              }
              this.qboRecalculationRequest.push(req);
            }
            else {
              var req: QboRecalculationRequest = {
                Id: row.avataxTxnId,
                EntityType: row.avataxTxnType.replace(/\s+/g, ""),
                SyncToken: row.syncToken
              }
              this.qboRecalculationRequest.push(req);
            }

          }
        }
        if (this.qboRecalculationRequest.length > 0) {
          var current = this;
          current.spinnerService.show();
          this.oAuthToken = this.authService.fetchAIAuthTokenFromStorage();
          if (this.oAuthToken) {
            this.reconciliationService.recalculateQboTransactions(this.oAuthToken.userId, current.qboRecalculationRequest, this.oAuthToken.session_Id)
              .subscribe(
                (model: any) => {

                  if (model.userStatus != "Active") {
                    alert("Integration status (Calculation active) must be enabled for this feature, please go back to home screen and enable it!");
                  }
                  else if (model.error) {
                    if (model.error == "NoRecords") {
                      alert("No transactions found for recalculation.");
                    }
                  }
                  else {
                    alert("QuickBooks transactions will get recalculated in the background. \n 1. It will take sometime according to the transaction count.\n 2. Please DO NOT PROCESS same transactions again to avoid conflict.\n 2. Please wait and refresh to check transaction are processed.");

                    current.qboRecalculationRequest = [];
                    for (var row of current.inconsistentList) {
                      row.isSelected = false;
                    }
                  }
                  current.spinnerService.hide();
                },
                (error: any) => {
                  this.errorMessage = <any>error;
                  this.spinnerService.hide();
                  if(this.errorMessage != "unauthorized")
                    alert(this.errorMessage);
                },
                () => { }
              );
          }
        }
      }
      else {
        alert("Please select QuickBooks transactions for recalculation.");
      }
    }
    else {
      alert('Session is timed out. Please log in again.')
      this.router.navigate(['/learnmore']);
    }

    return false;
  }

  hoverForTooltip(element: HTMLElement, message: string): void {
    const clone = element.cloneNode(true) as HTMLElement;
    clone.style.display = 'inline';
    clone.style.width = element.offsetWidth + 'px';
    clone.style.visibility = 'hidden';
    clone.style.position = 'absolute';
    
    document.body.appendChild(clone);
    const fullHeight = clone.offsetHeight;
    const actualHeight = element.offsetHeight;
    document.body.removeChild(clone);

    const result = fullHeight > actualHeight;
    if (result) {
      element.title = message;
    } else {
      element.removeAttribute('title');
    }
  }
}
