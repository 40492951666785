export class FetchTransactionsRequest 
{
    FromDate : string
    ToDate : string
    ErrorTransactionOnly: boolean
}

export class FetchTransactionsResponse 
{
    txnId : string
    txnNumber : string
    txnDate : string
    txnType : string
    totalAmt : string
    totalTax : string
    status : string
    syncToken: string
    category: string
}

export class RecTransactionsList
{
    isSelected:boolean
    qboTxnId : string
    qboTxnType: string
    txnDate : string
    qboInvNo : string
    avataxTxnId : string
    avataxTxnType: string
    qboTotAmt : string
    qboTotTax : string
    avataxTotAmt : string
    avataxTotTax : string
    syncToken: string
    errorMessage: string
    errorFrom: string
}


export class QboRecalculationRequest
{
    Id : string
    EntityType : string
    SyncToken: string
}