<s-container>
    <form [formGroup]="searchForm" novalidate (ngSubmit)="searchTransactions()">

        <a [routerLink]="[ '/home']" style="margin:0px;" *ngIf="this.sourceParam==''">
            &#8592; back
        </a>

        <div class="row" style="display: block !important">
            <h1 class="page-title" style="line-height: 2rem;"> Tax reconciliation utility </h1>
        </div>

        <div style="padding:0px;">

            <s-box class="reconciliation-search-box">
                <s-row>
                    <s-col span="3" style="padding-top: 9px;padding-bottom: 0px;">
                        <h3 style="line-height: 0px;">Filter Transactions</h3>
                        <h5 style="line-height: 0px;color:#8c8c8c">You can search transactions within 90-days limit.
                        </h5>
                    </s-col>
                    <s-col span="2" style="padding-bottom: 0px;">
                        <label for="input-id-1">From Date</label>

                        <input placeholder="mm/dd/yyyy" name="fromdate" [minDate]="setMinDate" [maxDate]="setMaxDate"
                            ngbDatepicker readonly="readonly" #fdate="ngbDatepicker" formControlName="FromDate"
                            AutomationAttribute="Reconciliation_Input_FromDate" style="display: inline;" />

                        <button style="height: 30px;" class="calendar-btn" (click)="fdate.toggle()"
                            AutomationAttribute="Reconciliation_Button_FromDateCalendar" type="button">
                            <s-icon name="calendar"></s-icon>
                        </button>
                    </s-col>
                    <s-col span="2" style="padding-bottom: 0px;">
                        <label for="input-id-1">To Date</label>
                        <input placeholder="mm/dd/yyyy" name="todate" [minDate]="setMinDate" [maxDate]="setMaxDate"
                            ngbDatepicker readonly="readonly" #tdate="ngbDatepicker" formControlName="ToDate"
                            AutomationAttribute="Reconciliation_Input_ToDate" style="display: inline;" />
                        <button style="height: 30px;" class="btn btn-outline-secondary calendar calendar-btn"
                            (click)="tdate.toggle()" AutomationAttribute="Reconciliation_Button_ToDateCalendar"
                            type="button">
                            <s-icon name="calendar"></s-icon>
                        </button>
                    </s-col>
                    <s-col span="2" style="padding-bottom: 0px; padding-top: 9px;">
                        <input type="checkbox" id="ErrorTransactionOnly" name="ErrorTransactionOnly"
                            formControlName="ErrorTransactionOnly" />
                        <label for="ErrorTransactionOnly">Show only error transactions</label>
                    </s-col>
                    <s-col span="3" style="padding-bottom: 0px;">
                        <label for="input-id-1">&nbsp;</label>
                        <button tabindex="-1" type="submit" class="primary" style="width:100px;margin-right: 5px;"
                            AutomationAttribute="Reconciliation_Button_Search">
                            <span class="button-text">
                                Search
                            </span>
                        </button>
                        <button tabindex="-1" type="button" class="secondary" style="width:100px;margin-right: 5px;"
                            (click)="clearForm()" AutomationAttribute="Reconciliation_Button_Search">
                            <span class="button-text">
                                Clear All
                            </span>
                        </button>
                    </s-col>
                </s-row>
            </s-box>
            <div class="row" [style.padding-bottom]="submitted && searchForm.invalid? '20px':'0px'">
                <ul style="margin-bottom:0px;">
                    <li class="input-error-msg" *ngIf="submitted && searchForm.get('FromDate').errors?.required"
                        style="color: rgb(197, 28, 28);list-style-type:none;">From Date is required.</li>

                    <li class="input-error-msg" *ngIf="submitted && searchForm.get('ToDate').errors?.required"
                        style="color: rgb(197, 28, 28);list-style-type:none;">To Date is required.</li>

                    <li class="input-error-msg"
                        *ngIf="submitted && !searchForm.get('ToDate').errors?.required && searchForm.errors?.dateLessThan"
                        style="color: rgb(197, 28, 28);list-style-type:none;">'To Date' should be greater than 'From
                        Date'.
                    </li>

                    <li class="input-error-msg"
                        *ngIf="submitted && !searchForm.get('ToDate').errors?.required && searchForm.errors?.dateLTET3Months"
                        style="color: rgb(197, 28, 28);list-style-type:none;">Date range should not be greater than 90
                        Days.
                    </li>
                </ul>
            </div>
            <br>
            <h2 style="line-height: 1rem;">Results</h2>
            <s-row class="reconciliation-result">
                <s-col span="3">
                    <s-box>
                        <s-icon class="medium" name="close-circle" style="color:#de2a2a;font-weight:bold;"></s-icon>
                        <div class="title">Inconsistent Transactions:
                            <span class="value" style="color:#de2a2a;">{{inConsistentCount}}</span>
                            <!-- <span class="link"><a href="#" class="text-underline">View</a> </span> -->
                        </div>
                    </s-box>
                </s-col>
                <s-col span="3">
                    <s-box>
                        <s-icon class="medium" name="alert-triangle-filled" style="color:#de2a2a;font-weight:bold;"></s-icon>
                        <div class="title">Error Transactions:
                            <span class="value" style="color:#de2a2a;">{{errorTransactionCount}}</span>
                        </div>
                    </s-box>
                </s-col>
                <s-col span="3">
                    <s-box>
                        <s-icon class="medium" name="check-circle" style="color:#3dad3c;font-weight:bold;"></s-icon>
                        <div class="title">Consistent Transactions:
                            <span class="value" style="color:#3dad3c;">{{consistentCount}}</span>
                            <!-- <span class="link"><a href="#" class="text-underline">View</a> </span> -->
                        </div>
                    </s-box>
                </s-col>
                <s-col span="3">
                    <s-box>
                        <s-icon class="medium" name="file-2" style="color:#333;font-weight:bold;"></s-icon>
                        <div class="title">Total Transactions:
                            <span class="value" style="color:#333;">{{totalCount}}</span>
                            <!-- <span class="link"><a href="#" class="text-underline">View</a> </span> -->
                        </div>

                    </s-box>
                </s-col>
            </s-row>
            <s-row *ngIf="avaTaxStartDate">
                <s-col span="12">
                <s-alert status="warning" nodismiss="">
                    <div>
                       <b>Please Note:</b> Transactions which are having Transaction Date greater than or equal to Start Date: {{ avaTaxStartDate }} will be reconciled.
                    </div>
                </s-alert>
                </s-col>
            </s-row>
            <s-row *ngIf="errorTransactionCount > 0">
                <s-col span="12">
                <s-alert status="info" nodismiss="">
                    <div>
                       <b>Please Note:</b> Review the error message and make the changes in QuickBooks Online. If the issue is not resolved, you can contact Intuit or Avalara support, fix the error, and come back here to reconcile transactions.
                    </div>
                </s-alert>
                </s-col>
            </s-row>
            <s-row>
                <s-col span="6" style="padding-bottom:10px">
                    <button tabindex="-1" class="primary" (click)="sendToRecalculation()"
                        AutomationAttribute="Reconciliation_Button_SendToRecalculation">
                        <span class="button-text">
                            Recalculate Tax
                        </span>
                    </button>
                    <a class="text-underline" style="padding:10px 20px;" *ngIf="isInconsistentList"
                        (click)="searchTransactions()">
                        <s-icon name="refresh"></s-icon> &nbsp;Refresh data
                    </a>
                </s-col>
                <s-col span="4">
                </s-col>
            </s-row>
            <s-row>
                <s-col>

                    <div class="row">
                        
                        <table datatable #dt1 class="card-table" [dtOptions]="dtOptions[0]" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th width="5%">
                                        <span style="margin: 2px 0 0 4px; position: absolute;font-weight:bold;">SELECT</span>
                                        <label *ngIf="isInconsistentList">
                                            <input type="checkbox" [checked]="isSelectAllChecked" style="width:16px;height:16px; display: inline;margin-top: 6px;margin-left: 15px;"
                                            (change)="selectAll($event)" />
                                        </label>
                                        </th>
                                    <th width="5%">QuickBooks<br> Txn Id</th>
                                    <th width="5%">Txn Date</th>
                                    <th width="5%">Txn Type</th>
                                    <th width="5%">QuickBooks<br>Invoice No.</th>
                                    <th width="5%">QuickBooks<br>Total Amt</th>
                                    <th width="5%">QuickBooks<br>Total Tax</th>
                                    <th width="5%">AvaTax<br>Txn Id</th>
                                    <th width="5%">AvaTax<br>Total Amt</th>
                                    <th width="5%">AvaTax<br>Total Tax</th>
                                    <th width="40%">Message</th>
                                    <th width="10%">Error From</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="row-collapse" *ngFor="let data of inconsistentList">
                                    <td style="text-align: center;">
                                        <fieldset role="group" aria-labelledby="label4">
                                            <div style="display: flex; justify-content: center; align-items: center;">
                                                <input type="checkbox" id="chkAll" name="chkAll" [checked]="data.isSelected"
                                                    style="width:16px;height:16px;"
                                                    (change)="selectTransactionValue($event.target);"
                                                    value="{{data.qboTxnId}}" />
                                            </div>
                                        </fieldset>
                                    </td>
                                    <td style="text-align: center;">{{data.qboTxnId}}</td>
                                    <td>{{data.txnDate | date: 'MM/dd/yyyy' }}</td>
                                    <td>{{data.qboTxnType}}  <i *ngIf="!data.qboTxnType" style="color:#949494;"> {{ "Missing in QuickBooks"}} </i> </td>
                                    <td style="text-align: center;">{{data.qboInvNo}} <i *ngIf="!data.qboInvNo" style="color:#949494;"> {{ "Missing in QuickBooks"}} </i> </td>
                                    <td style="text-align: center;">{{data.qboTotAmt}} <i *ngIf="!data.qboTotAmt" style="color:#949494;"> {{ "Missing in QuickBooks"}} </i> </td>
                                    <td style="text-align: center;"> <span style="color:red;font-weight:bold">{{data.qboTotTax}}</span>   <i *ngIf="!data.qboTotTax" style="color:#949494;"> {{ "Missing in QuickBooks"}} </i> </td>
                                    <td style="text-align: center;">{{data.avataxTxnId}}  <i *ngIf="!data.avataxTxnId" style="color:#949494;"> {{ "Missing in AvaTax"}} </i></td>
                                    <td style="text-align: center;">{{data.avataxTotAmt}} <i *ngIf="!data.avataxTotAmt" style="color:#949494;"> {{ "Missing in AvaTax"}} </i> </td>
                                    <td style="text-align: center;"> <span style="color:red;font-weight:bold">{{data.avataxTotTax}}</span><i *ngIf="!data.avataxTotTax" style="color:#949494;"> {{ "Missing in AvaTax"}} </i> </td>
                                    <td style="text-align: left;width:100px;">
                                        <div class="message-container">
                                            <span #errorMessageElement
                                                class="errorMessage"
                                                (mouseover)="hoverForTooltip(errorMessageElement, data.errorMessage)"
                                            >{{data.errorMessage}}</span>
                                            <span class="copy-container">
                                                <span class="copy-icon" (click)="copyText(data.errorMessage)">
                                                    <s-icon name="copy" alt="copy" title="Click to Copy"></s-icon>
                                                    <span class="copy-tooltip" *ngIf="showCopiedMessage">Copied!</span>
                                                </span>
                                            </span>
                                        </div>
                                    </td>
                                    <td style="text-align: center;width:100px;">{{data.errorFrom}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </s-col>
            </s-row>
        </div>
    </form>
</s-container>
